import Slider from "react-slick";
import { useEffect, useMemo, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Steps from "./Steps";

const ProductDetailsModal = ({ tab, productsInfo, closeModal }) => {
  const { product, frameGroup } = productsInfo;

  const [localProduct, setLocalProduct] = useState(null);
  const [activeColor, setActiveColor] = useState(tab === "sunglasses" ? 'Smoke' : '');
  const [activeType, setActiveType] = useState("Single Vision");
  const [activeUpgrades, setActiveUpgrades] = useState(['Standard']);

  const checkUpgrades = (el) => {
    if (!activeUpgrades.length) return true;

    for (const upgrade of activeUpgrades) {
      if (!el.title.includes(upgrade)) return false;
    }
    
    return true;
  }
  useEffect(() => {
    if (activeColor || activeType || activeUpgrades.length) {
      setLocalProduct(
        frameGroup.find(
          (el) =>
            el.title.includes(activeColor) &&
            el.title.includes(activeType) &&
            checkUpgrades(el)
        )
      );
    }
  }, [activeColor, activeType, activeUpgrades]);

  const sliderSettings = useMemo(
    () => ({
      dots: true,
      className: "custom-dots",
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 5,
      autoplay: false,
      arrows: false,
    }),
    []
  );  


  return (
    <>
      <div className="flex flex-col lg:flex-row fixed w-full h-full inset-0 z-50 items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
        <div className="w-full xl:w-[100%] h-fit lg:h-full mx-auto bg-white">
          <div className="flex justify-between items-center w-full p-[50px] pb-0">
            <button
              onClick={closeModal}
              className="text-[#262626] bg-white border border-[#E6E6E6] z-10 rounded-[18px] text-[14px] font-bold px-[21px] py-5px pt-[7px] pb-[7px]"
            >
              Exit
            </button>
            <div className="flex flex-col">
              <h3 className="text-[#232B38] font-serif font-extrabold text-right text-[18px]">{product.mainTitle}</h3>
              <h4 className="text-[#232B38] font-serif text-right text-[16px]">{product.frameTitle}</h4>
            </div>
          </div>
          <Slider {...sliderSettings}>
            {localProduct ? (
              <div className="h-full">
                <div className="h-full flex justify-center items-center">
                  <img
                    src={localProduct.image.src}
                    alt={`Slide ${localProduct.title}`}
                    className="h-96 w-full object-contain"
                  />
                </div>
              </div>
            ) : (
              <div className="h-full">
                <div className="h-full flex justify-center items-center">
                  <img
                    src={product.image.src}
                    alt={`Slide ${product.title}`}
                    className="h-96 w-full object-contain"
                  />
                </div>
              </div>
            )}
          </Slider>
        </div>
        <div className="overflow-auto w-full h-full bg-white">
          <Steps
            product={localProduct || product}
            closeModal={closeModal}
            tab={tab}
            products={frameGroup}
            setActiveColor={setActiveColor}
            activeColor={activeColor}
            activeUpgrades={activeUpgrades}
            setActiveUpgrades={setActiveUpgrades}
            activeType={activeType}
            setActiveType={setActiveType}
          />
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-50"> </div>
    </>
  );
};

export default ProductDetailsModal;
