import { useMemo } from "react";

const Step2 = ({ prescriptionType, activeType, setActiveType }) => {
  const typeDescriptions = useMemo(
    () => [
      {
        type: "Single Vision",
        description: "Corrects for one field of vision-most common",
      },
      {
        type: "Progressive",
        description:
          "Corrects for multiple fields of vision for eyes that struggle in transitioning from distance to close-up objects",
      },
    ],
    []
  );
  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex lg:flex-col flex-row justify-between">
        <p className="text-[#232B38] font-serif font-extrabold text-[24px]">
          Lens Type
        </p>
      </div>
      {prescriptionType.map((el) => (
        <div onClick={() => setActiveType(el.type)} key={el.type} className="flex justify-between items-center">
          <div className="flex gap-[10px] items-start">
            <div
              className={`p-[3px] h-fit rounded-full border-2 ${
                activeType === el.type ? "border-blue-400" : "border-gray-400"
              }`}
            >
              <div
                className={`w-[14px] h-[14px] rounded-full ${
                  activeType === el.type ? "bg-blue-400" : ""
                }`}
              />
            </div>
            <div className="flex flex-col">
              <p>{el.type}</p>
              {typeDescriptions.map(
                (type) =>
                  type.type === el.type && (
                    <p key={type.type}>{type.description}</p>
                  )
              )}
            </div>
          </div>
          <p className="uppercase">{el.price ? `+$${el.price}` : "Free"}</p>
        </div>
      ))}
    </div>
  );
};

export default Step2;
