import { useMemo } from 'react';

const Step3 = ({ onUpgradeChange, upgrade, upgradeTypes }) => {
  const upgrades = useMemo(() => ({
    'High Index': 'Thinner lenses recommended for strong prescriptions',
    'Polarized': 'Extra protection against glare from light built right into your glasses'
  }), []);

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex lg:flex-col flex-row justify-between">
        <p className="text-[#232B38] font-serif font-extrabold text-[24px]">Lens Upgrades</p>
      </div>
      <div className="flex flex-col gap-[10px]">
          {upgradeTypes.sort((a, b) => (a.price || 0) - (b.price || 0)).map((el) => (
            <div key={el.upgrade} className="flex gap-[10px]">
              <div
                className={`flex justify-center items-center min-w-[26px] h-[26px] border-2 ${upgrade.includes(el.upgrade) ? 'bg-[#08B1FF] border-[#08B1FF]' : 'border-[#E6E6E6]'} rounded`}
                onClick={() => onUpgradeChange(el.upgrade)}>
                {upgrade.includes(el.upgrade) && <img src="/svgs/checked.svg" alt="checked"/>}
              </div>
              <div className="flex w-full flex-row justify-between">
                <div className="flex flex-col">
                  <p>{el.upgrade}</p>
                  <p>{upgrades[el.upgrade]}</p>
                </div>
                <p className="uppercase">{el.price ? `+$${el.price}` : 'Free'}</p>
              </div>
            </div>
          ))          
        }
      </div>
    </div>
  );
};

export default Step3;