import { useRef } from 'react';

const FileInput = ({ label, onChange, value }) => {
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    onChange(event.target.files[0]);
  };

  return (
    <div className="grid justify-items-center py-[10px]">
      <input
        type="file"
        accept="image/*" 
        capture="camera" 
        ref={inputRef}
        className="hidden appearance-none bg-transparent border-none cursor-pointer focus:outline-none"
        onChange={handleInputChange}
      />
      <button
        type="button"
        onClick={() => inputRef.current.click()}
        className="flex items-center gap-[7px] px-[30px] py-[10px] bg-[#002970] border border-transparent rounded-[20px] font-semibold text-[12px] text-white uppercase tracking-widest hover:bg-gray-600 active:bg-gray-800 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray disabled:opacity-25 transition ease-in-out duration-150"
      >
        <img src="/svgs/camera-icon.svg" alt="photo icon"/>
        {label}
      </button>
      {value && <p className="pt-[10px]">{value.name}</p>}
    </div>
  );
}

export default FileInput;
