import { FaSearch, FaChevronRight } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import Navlink from './Navlink';
import './styles.css'; // replace this with the path to your CSS file

const Navbar = ({ activeTab, showSearchBar, setShowSearchBar, searchBarValue, setSearchBarValue }) => {

  const handleSearchIconClick = () => {
    setShowSearchBar(!showSearchBar);
    setSearchBarValue("");
  };
  const handleSearchTextChange = (event) => {
    setSearchBarValue(event.target.value);
  };

  return (
    <div className="px-[50px] py-[10px] border-b border-gray-300">
      <div className="flex py-[10px] gap-[15px] items-center ">
        <Navlink to="sunglasses" activeTab={activeTab} />
        <Navlink to="eyeglasses" activeTab={activeTab} />
        <div className="flex items-center ml-auto overflow-hidden">
          <div onClick={handleSearchIconClick} className="ml-2">
            {showSearchBar ? <FaChevronRight /> : <FaSearch />}
          </div>
          <CSSTransition
            in={showSearchBar}
            timeout={200}
            classNames="search-bar-container"
            unmountOnExit
          >
            <div className="px-4 py-2 border border-gray-300">
              <input
                type="text"
                placeholder="Search..."
                className="w-full focus:outline-none"
                value={searchBarValue}
                onChange={handleSearchTextChange}
              />
            </div>
          </CSSTransition>

        </div>
      </div>
    </div>
  );
};

export default Navbar;
