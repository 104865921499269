import { Link, useParams } from 'react-router-dom';
import Navbar from './Navbar';

const Header = ({ activeTab, showSearchBar, setShowSearchBar, searchBarValue, setSearchBarValue }) => {
  const { shopId } = useParams();
  return (
    <div>
      <div className="h-[144px] w-full bg-[#002970] flex flex-col items-center justify-between">
        <Link to={`/${shopId}`}>
          <img src="/images/logo.png" alt="Blenders Logo" className="mt-[23px] w-[220px]"/>
        </Link>
        <div className="bg-[#F2F4F7] h-[42px] flex justify-center items-center w-full">
          <p className="text-[#002970] text-[14px] text-center">Expect to receive your glasses in 5-7 business days from
            when we confirm your prescription.</p>
        </div>
      </div>
      <Navbar activeTab={activeTab} showSearchBar={showSearchBar} setShowSearchBar={setShowSearchBar} searchBarValue={searchBarValue} setSearchBarValue={setSearchBarValue}/>
    </div>
  );
};

export default Header;
