import { useState } from "react";
import CheckoutWithName from "./checkout/checkoutWithName";
import { useNavigate, useParams } from "react-router-dom";

const InfoModal = ({
  closeModal,
  firstName,
  setFirstName,
  submitWithEmail,
  email,
  lastName,
  setEmail,
  setLastName,
  submitWithName,
}) => {
  const [checkoutWithName, setCheckoutWithName] = useState(false);
  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  let navigate = useNavigate();
  const { shopId } = useParams();
  
  const validateName = (name) => {
    const regex = /^[a-zA-Z\s]*$/;
    return regex.test(name);
  };

  const goToCheckoutName= async (e) => {
    e.preventDefault();
    if (!validateName(firstName) || firstName.length < 3) {
      setNameError('Please enter valid name')
      return;
    }
    if(!validateName(lastName) || lastName.length < 3) {
      setLastNameError("Please enter valid last name")
      return;
    }
    setCheckoutWithName(true);
    submitWithName(e);
  };

  return (
    <div className="flex flex-col lg:flex-row fixed w-full h-full inset-0 z-50 items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="w-full h-full p-[20px] mx-auto bg-white">
        <div className="px-[30px] py-[10px]">
          <button
            onClick={closeModal}
            className="text-[#262626] bg-white border border-[#E6E6E6] rounded-[18px] text-[14px] font-bold px-[10px] py-5px"
          >
            Exit
          </button>
        </div>
        <div className="gap-[15px] mx-auto h-[calc(100%-30px)] w-[520px] flex flex-col justify-center [&_p]:text-[#212121] [&_p]:text-center [&_p]:text-[16px] leading-[22px] [&_input]:border [&_input]:border-[#E6E6E6] [&_input]:h-[36px] [&_input]:px-[10px]">
          <p className="text-[#232B38] !text-[32px] font-serif font-extrabold">
            Enter Your Information
          </p>
          <p>
            Give us your name to attach to your customized prescription glasses.
          </p>
          <div className="flex flex-col w-full gap-[10px] py-[15px]">
            <input
              type="text"
              value={firstName}
              onChange={({ target: { value } }) => setFirstName(value)}
              placeholder="First Name"
            />
            {<span className="text-red-500">{nameError}</span>}
            <input
              type="text"
              value={lastName}
              onChange={({ target: { value } }) => setLastName(value)}
              placeholder="Last Name"
            />
            {<span className="text-red-500">{lastNameError}</span>}
          </div>
          <button
            onClick={goToCheckoutName}
            className="flex justify-center items-center transition rounded-[3px] ease-in-out duration-200 w-full h-[44px] bg-gradient-to-r from-[#0457A1] hover:from-[#8CD9F5] to-[#002668] hover:to-[#0175D4] text-white uppercase text-[14px] font-bold"
          >
            submit
          </button>
        </div>
      </div>
      {checkoutWithName && (
        <CheckoutWithName closeModal={closeModal} firstName={firstName} navigate={navigate} shopId={shopId}/>
      )}
    </div>
  );
};

export default InfoModal;
