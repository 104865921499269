const Product = ({ product }) => {
  return (
    <div className="w-[355px] flex flex-col">
      <div className="w-full mb-[20px] h-[260px] flex items-center justify-center">
        <img
          src={product.image.src}
          alt={`${product.frameTitle} ${product.title}`}
        />
      </div>
      <h4 className="text-[#212121] text-[16px] pb-6">{product.frameTitle}</h4>
    </div>
  );
};

export default Product;
