import { useMemo, useState } from "react";
import LensGuide from "../../../../Guides/LensGuide";

const Index = ({ lensColors, setActiveColor, activeColor }) => {
  const [lensGuide, setLensGuide] = useState(false);

  const colorImages = useMemo(
    () => ({
      Smoke: "/images/lens-black.png",
      Amber: "/images/lens-brown.png",
      Blue: "/images/lens-blue.png",
      Pink: "/images/lens-pink.png",
      Champagne: "/images/lens-champagne.png",
    }),
    []
  );

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex lg:flex-col flex-row justify-between">
        <p className="text-[#232B38] font-serif font-extrabold text-[24px]">
          Lens Color
        </p>
        <p
          onClick={() => setLensGuide(true)}
          className="underline text-[16px] text-[#212121] font-bold"
        >
          Lens Guide
        </p>
      </div>
      {lensColors.map((el) =>
        el.color === "Clear" ? null : (
          <div key={el.color} className="flex flex-row justify-between">
            <div className="flex items-center gap-[10px] text-[#232B38] font-bold text-[18px] capitalize">
              <div
                onClick={() => setActiveColor(el.color)}
                className={`${
                  el.color === activeColor
                    ? "border-2 p-[3px] border-blue-400 rounded-full"
                    : "border-2 p-[3px] border-transparent"
                }`}
              >
                {colorImages[el.color] ? (
                  <img src={colorImages[el.color]} alt={el.color + "-image"} />
                ) : (
                  <div className="w-[32px] h-[32px] border border-black rounded-full" />
                )}
              </div>
              <div>{el.color}</div>
            </div>
            <p className="text-[16px] text-[#212121] uppercase">{el.price ? `+$${el.price}` : 'Free'}</p>
          </div>
        )
      )}
      {lensGuide && <LensGuide closeModal={() => setLensGuide(false)} />}
    </div>
  );
};

export default Index;
