import { useMemo } from 'react';

const StepProgressBar = ({ currentStep, stepCount }) => {
  const steps = useMemo(() => {
    const arr = [];

    for (let i = 1; i <= stepCount; ++i) arr.push(<div
        key={i} 
        className={`h-2 w-1/3 ${currentStep >= i ? 'bg-blue-900' : 'bg-gray-300'} ${i === 1 && 'rounded-l-full'} ${i === stepCount && 'rounded-r-full'}`}
        style={{
          transition: 'width 1s ease-in-out',
        }}
      />
    );

    return arr;
  }, [currentStep, stepCount]);

  return (<div className="flex items-center justify-between w-full">{steps}</div>);
};

export default StepProgressBar;