const LensGuide = ({ closeModal }) => {
  return (
    <>
      <div
        className="flex-col lg:flex-row fixed w-full h-full inset-0 z-50 flex items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
      >
        <div className="w-[460px] h-[403px] p-[20px] mx-auto bg-white rounded-[10px]">
          <div className="flex flex-row justify-end">
            <img src="/svgs/close-icon.svg" alt="close" onClick={closeModal}/>
          </div>
          <div className="flex px-[20px] pb-[50px] flex-col h-full justify-between text-[16px] text-[#212121] leading-[21px]">
            <p className="text-center text-[#232B38] font-serif font-extrabold text-[32px]">Lens Guide</p>
            <div>
              <p className="font-bold text-[18px]">Solid</p>
              <p className="italic text-[18px]">Smoke and Amber</p>
              <p>Our solid lenses help you “beat the blur” in your choice of one of our fan-favorite
                sunglass colors, designed to shield you from harmful UVA/UVB rays.</p>
            </div>
            <div>
              <p className="font-bold text-[18px]">Mirrored</p>
              <p className="italic text-[18px]">Blue, Pink, and Champagne</p>
              <p>Level up your look with our brilliant mirrored lenses. These colorful stunners excel at bouncing light
                away, further reducing glare when outdoors and offering comprehensive UVA/UVB protection.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
    </>
  );
};

export default LensGuide;
