export const getColors = (products) => {

  const filteredProducts = products.filter((product) => 
    product.title.includes("Single Vision") && product.title.includes("Standard")
  );

  let standardPrice = Infinity;

  for (const product of filteredProducts) {
    const currentPrice = +product.priceV2.amount;
    if (currentPrice < standardPrice && !product.title.includes("Clear")) {
      standardPrice = currentPrice;
    }
  }

  const colors = filteredProducts.map((product) => {
    const selectedOption = product.selectedOptions.find((option) => option.name === "Lens Color");
    const price = +product.priceV2.amount - standardPrice;
    return { color: selectedOption.value, price: price };
  });

  return colors;
};

export const getTypes = (products, color = 'Clear') => {
  const filteredByTypes = products.filter(
    (el) => el.title.includes(color) && el.title.includes("Standard")
  );

  const standardPrice = +products[0].priceV2.amount;
  const types = [];

  for (const item in filteredByTypes) {
    if (!types[item]) {
      types.push({
        type: filteredByTypes[item].selectedOptions.find(
          (option) => option.name === "Prescription Type"
        ).value,
        price: +filteredByTypes[item].priceV2.amount - standardPrice,
      });
    }
  }
  return types;
};

export const getUpgrades = (products, color, type) => {
  const filteredByUpgrades = products.filter(
    (el) => el.title.includes(color) && el.title.includes(type)
  );
  const standardPrice = +products[0].priceV2.amount;
  const upgrades = [];

  for (const item in filteredByUpgrades) {
    if (!upgrades[item]) {
      upgrades.push({
        upgrade: filteredByUpgrades[item].selectedOptions.find(
          (option) => option.name === "Lens Upgrades"
        ).value,
        price: +filteredByUpgrades[item].priceV2.amount - standardPrice,
      });
    }
  }
  return upgrades;
};
