import { createBrowserRouter } from 'react-router-dom';
import Opening from '../pages/Opening';
import Glasses from '../pages/Glasses';
import Default from '../pages/Default';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Default/>
  },
  {
    path: '/:shopId',
    element: <Opening/>
  },
  {
    path: 'sunglasses/:shopId',
    element: <Glasses tab="sunglasses"/>,
  },
  {
    path: 'eyeglasses/:shopId',
    element: <Glasses tab="eyeglasses"/>,
  },
]);

export default router;