import Step1 from "../Step1";
import Step2 from "../Step2";
import Step3 from "../Step3";
import { useEffect, useMemo } from "react";
import Step4 from "../Step4";
import {
  getColors,
  getTypes,
  getUpgrades,
} from "../../../../../utils/functions";

const SunglassesSteps = ({
  tab,
  step,
  file,
  setFile,
  setPrescription,
  upgrade,
  sections,
  products,
  detailType,
  activeType,
  activeColor,
  setActiveType,
  setDetailType,
  setActiveColor,
  onUpgradeChange,
  confirmationAccept,
  setConfirmationAccept,
  puppilaryDistanceCount,
  setPuppilaryDistanceCount,
}) => {
  const lensColors = useMemo(() => {
    if (tab !== "sunglasses") return null;
    return getColors(products);
  }, [products]);

  const prescriptionType = useMemo(() => {
    return getTypes(
      products.filter((el) => el.title.includes(activeColor)),
      activeColor
    );
  }, [activeColor, tab, products]);

  const upgradeTypes = useMemo(() => {
    return getUpgrades(
      products.filter(
        (el) => el.title.includes(activeColor) && el.title.includes(activeType)
      ),
      activeColor,
      activeType
    );
  }, [activeColor, activeType, tab, products]);

  return (
    <>
      {step === 1 && (
        <Step1
          activeColor={activeColor}
          setActiveColor={setActiveColor}
          lensColors={lensColors}
        />
      )}
      {step === 2 && (
        <Step2
          activeType={activeType}
          setActiveType={setActiveType}
          prescriptionType={prescriptionType}
        />
      )}
      {step === 3 && (
        <Step3
          onUpgradeChange={onUpgradeChange}
          upgrade={upgrade}
          upgradeTypes={upgradeTypes}
        />
      )}
      {step === 4 && (
        <Step4
          file={file}
          setFile={setFile}
          setPrescription={setPrescription}
          activeType={activeType}
          detailType={detailType}
          setDetailType={setDetailType}
          confirmationAccept={confirmationAccept}
          setConfirmationAccept={setConfirmationAccept}
          puppilaryDistanceCount={puppilaryDistanceCount}
          setPuppilaryDistanceCount={setPuppilaryDistanceCount}
        />
      )}
      {step === 5 && (
        <div className="flex flex-col gap-[10px]">
          <div className="flex lg:flex-col flex-row justify-between">
            <p className="text-[#232B38] font-serif  font-extrabold text-[24px]">
              Review Your Selections
            </p>
          </div>
          <div className="flex flex-col gap-[20px]">
            {sections.map((el) => (
              <div key={el.title} className="flex flex-row">
                <div className="flex flex-col gap-[10px]">
                  <p className="font-bold text-[#212121] text-[18px]">
                    {el.title}
                  </p>
                  {el.title === "Lens Upgrade" && tab === "sunglasses" ? (
                    <p className="text-[#212121] text-[16px]">
                      {el.value.join(" + ")}
                    </p>
                  ) : (
                    <p className="text-[#212121] text-[16px]">{el.value}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SunglassesSteps;
