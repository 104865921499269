import Products from '../../components/Products';
import Header from '../../components/shared/Header';

const Glasses = ({ tab }) => {

  return (
    <div className="flex items-center justify-center h-screen bg-[#002970]">
      <img src="/images/logo.png" alt="Blenders Logo" className="w-1/3" />
    </div>
  )
}

export default Glasses;
