import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

const Opening = () => {

  const navigate = useNavigate();
  const { shopId } = useParams();

  useEffect(() => {
    if (!/^\d+$/.test(shopId)) {
      navigate('/');
    }
  }, []);

  return (
    <div
      className="text-center w-full max-w-[1366px] mx-auto h-screen bg-hero-mobile md:bg-hero-desktop bg-no-repeat bg-cover bg-center">
      <div className="mx-auto w-full max-w-[640px] flex flex-col justify-end items-center h-full p-[20px]">
        <img src="/images/logo.png" alt="Blenders Logo" className="mb-auto mt-[50px]"/>
        <h2 className="text-[32px] text-[#232B38] font-serif font-extrabold mb-[15px]">
          Build Your Prescription Glasses
        </h2>
        <p className="text-[#212121] text-[16px] mb-[50px]">
          Right here in the store, build your own prescription eyeglasses or sunglasses. When you're done, pay for them
          at the register and we’ll send them to you.
        </p>
        <Link
          to={`/sunglasses/${shopId}`}
          className="flex justify-center items-center transition rounded-[3px] ease-in-out duration-200 w-full h-[44px] bg-gradient-to-r from-[#0457A1] hover:from-[#8CD9F5] to-[#002668] hover:to-[#0175D4] text-white uppercase text-[14px] font-bold mb-[30px]"
        >
          Sunglasses
        </Link>
        <Link
          to={`/eyeglasses/${shopId}`}
          className="flex justify-center items-center transition justify-center items-center rounded-[3px] ease-in-out duration-200 w-full h-[44px] bg-gradient-to-r from-[#0457A1] hover:from-[#8CD9F5] to-[#002668] hover:to-[#0175D4] text-white uppercase text-[14px] font-bold mb-[80px]"
        >
          EyeGlasses
        </Link>
      </div>
    </div>
  );
};

export default Opening;