import { useState } from 'react';
import Products from '../../components/Products';
import Header from '../../components/shared/Header';

const Glasses = ({ tab }) => {

  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchBarValue, setSearchBarValue] = useState('');

  return (
    <>
      <Header activeTab={tab} showSearchBar={showSearchBar} setShowSearchBar={setShowSearchBar} searchBarValue={searchBarValue} setSearchBarValue={setSearchBarValue}/>
      <Products tab={tab} searchBarValue={searchBarValue}/>
    </>
  )
}

export default Glasses;
