import React from "react";

function checkoutWithName({ closeModal, firstName, navigate, shopId }) {

  const routeChange = () =>{ 
    let path = `/` + shopId;
    navigate(path);
  }


  return (
    <div className="absolute w-full h-screen bg-white">
      <div className="w-full h-full p-[20px] mx-auto">
        <div className="px-[30px] py-[10px]">
          <button
            onClick={closeModal}
            className="text-[#262626] bg-white border border-[#E6E6E6] rounded-[18px] text-[14px] font-bold px-[10px] py-5px"
          >
            Exit
          </button>
        </div>

        <div className="flex justify-center items-center h-5/6">
          <div className="text-center">
            <span className="text-base text-[#212121]">
              Thank you {firstName}!
            </span>
            <p className="text-[#232B38] !text-[32px] font-serif font-extrabold">
              You’re Ready To Checkout!
            </p>
            <span className="max-w-lg">
              Please find one of our team members who can collect payment and{" "}
              <br /> confirm this order.
            </span>
            <button onClick={routeChange} className="flex justify-center items-center transition rounded-[3px] ease-in-out duration-200 w-full h-[44px] bg-gradient-to-r from-[#0457A1] hover:from-[#8CD9F5] to-[#002668] hover:to-[#0175D4] text-white uppercase text-[14px] font-bold mt-4">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default checkoutWithName;
