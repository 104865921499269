import { Link, useParams } from 'react-router-dom';

const NavLink = ({ to, activeTab }) => {
  const { shopId } = useParams();
  return (
  <Link
    to={`/${to}/${shopId}`}
    className={`uppercase text-[16px] font-bold py-2 ${activeTab === to ? 'text-[#212121] border-b-2 border-[#212121]' : 'text-[#BEBEBE]'}`}
  >
    {to}
  </Link>
)};

export default NavLink;
